import "lazysizes";
import Splide from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import {FloatLabels} from "@keodesign/float-labels";

var html_classes = document.documentElement.classList;
html_classes.remove("no-js");
html_classes.add("js");
window.onload = function() {    
	setupSlideMenu();
	setupSlider();
	setupGalleryAutoScroll();
	window.kms_bannerRotate({"delay":3000});
	new FloatLabels(".js-float-wrap");
	const map_click = document.getElementById("map-click");
	if (map_click) {
		map_click.addEventListener("click",() => {
			// shield the map on mobile for easy scrolling
			map_click.style.display = "none";
		});
	}
};

export function setupSlideMenu(){
	const menu_buttons = [...document.querySelectorAll(".mobile-menu, .slide-menu h3")];
	menu_buttons.forEach(el => {
		el.addEventListener("click", ()=>{
			$("body").toggleClass("slide-menu-active");
		});
	});
	const menu_items = [...document.querySelectorAll(".slide-menu li")];
	menu_items.forEach((element) => {		
		if ([...element.querySelectorAll("ul")].length > 0) {
			element.insertAdjacentHTML("beforeend","<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
			
			// expander.click(function(e) {
			// 	e.stopPropagation();
			// 	$el.toggleClass("expand-active");
			// });
		}
	});
	const menu_expanders = [...document.querySelectorAll(".slide-menu .expand")];
	menu_expanders.forEach(el => {
		el.addEventListener("click", (e)=> {
			e.stopPropagation();
			el.closest("li").classList.toggle("expand-active");
		});
	});

}

export function setupGalleryAutoScroll(){
	var elms = document.getElementsByClassName("gallery-autoscroll");
	for ( var i = 0; i < elms.length; i++ ) {
		new Splide( elms[i], {
			type   : "loop",
			drag   : "free",
			pagination: false,
			arrows    : false,
			focus  : "center",
			perPage: 6,
			autoWidth: true,
			autoScroll: {
				speed: 1,
				rewind: true
			},
			mediaQuery : "max",
			breakpoints: {
				998: {
					perPage: 4,
				},
				768: {
					perPage: 3,
				},
				576: {
					perPage: 2,
				},
				300: {
					perPage: 1,
				},
			}
		}).mount( { AutoScroll } );
	}
}

//add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener("lazybeforeunveil", function(e){
	var container = e.target;
	var bgUrl = container.dataset.bgUrl;
	if (bgUrl) {
		container.style.backgroundImage = "url('" + bgUrl + "')";
		delete container.dataset.bgUrl;
	}
});


export function setupSlider(){
	var elms = document.getElementsByClassName("slider");
	for ( var i = 0; i < elms.length; i++ ) {
		new Splide( elms[i], {
			type   : "slide",
			rewind    : true,
			pagination: false,
			arrows    : false,
			autoplay: true,
			interval: 5000,
			perPage: 1,
		}).mount();
	}
}